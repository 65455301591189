import React from 'react';
import './AffiliateCard.css'; 

const AffiliateCard = ({ title, description, link, imageUrl }) => {
    const handleClick = () => {
        window.open(link, "_blank");
    };
    return (
        <div className="card" onClick={handleClick}>
            <img src={imageUrl} alt={title} className="card-image"/>
            <div className="card-body">
                <h3 className="card-title">{title}</h3>
                <p className="card-description">{description}</p>
                <a href={link} target="_blank" rel="noopener noreferrer" className="card-link">Learn More</a>
            </div>
        </div>
    );
};

export default AffiliateCard;
