
import AffiliateCard from './components/AffiliateCard';
import './App.css'
import shopify from './logos/shopify.png'
import creativefabrica from './logos/creativefabrica.png'
import merchdominator from './logos/merchdominator.jpeg'
import rakuten from './logos/rakuten.jpeg'
import bookbolt from './logos/bookbolt.png'

function App() {
  return (
    <div className="app-grid">
      <AffiliateCard 
        title="Shopify" 
        description="Build your website and start making money with only 1$ a month!" 
        link="https://shopify.pxf.io/DKZEDa" 
        imageUrl={shopify}
      />
      <AffiliateCard 
        title="Merch Dominator" 
        description="This is the best tool for POD especially merch by amazon!" 
        link="https://merchdominator.com/pricing?ref=zdvinte" 
        imageUrl={merchdominator}
      />
      <AffiliateCard 
        title="CreativeFabrica" 
        description="You will find any digital product here with resell rights to save time!" 
        link="https://www.creativefabrica.com/ref/5482232/" 
        imageUrl={creativefabrica}
      />
      <AffiliateCard 
        title="Rakuten" 
        description="Save money & Get free discounts & coupon codes from most famous sites that you already buy from!" 
        link="www.rakuten.com/r/AYMENN12?eeid=28187" 
        imageUrl={rakuten}
      />
      <AffiliateCard 
        title="Book Bolt" 
        description="Amazon kdp life hack!" 
        link="https://bookbolt.io/5329.html" 
        imageUrl={bookbolt}
      />
    </div>
  );
}

export default App;
